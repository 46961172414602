import React, {PureComponent} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 50px;
  background-color: var(--bg-green);
  display: flex;
  align-item: center;
`
const Content = styled.div`
  display: flex;
  align-item: center;
`

class NavBar extends PureComponent {
  render() {
    return (
      <Container>
        <Content>
          NavBar
        </Content>

      </Container>
    )
  }
}

NavBar.propTypes = {}

export default NavBar

import React, {PureComponent} from 'react';
import styled from 'styled-components';
import NavBar from '../../components/nav-bar/NavBar';
import SingleRecipe from './SingleRecipe';
import demoRecipes from './demo-data/recipes';

const Header = styled.div`
  height: 210px;
  background-color: #cef4e8;
  margin-bottom: -30px;
`;

class RecipesContainer extends PureComponent {
  render() {
    return (
      <div>
        <NavBar />
        <Header/>
        <div className="container">
          <div className="row">
            {
              demoRecipes.map(( recipe, i ) => (
                <SingleRecipe
                  key={i}
                  {...recipe}
                />
              ))
            }
          </div>
        </div>
      </div>
    )
  }
}

RecipesContainer.propTypes = {};

export default RecipesContainer

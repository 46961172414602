import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: ${ props => props.center ? 'center' : 'flex-start' };
  align-items: center;
  color: #d9d9d9;
  font-size: 14px;
`;

const Item = styled.div`
  padding: 4px;
`;

const Star = ({
  onMouseOver,
  onRate,
  i,
  className
}) => (
  <Item
    onMouseOver={ () => onMouseOver(i) }
    onClick={ () => onRate(i) }>
      <i className={className} />
  </Item>
);


class RatingStars extends PureComponent {

  getClassName = (i) => {
    const { hovered } = this.props;
    if ( hovered === -1 ) {
      return this.isNotHovered(i)
    }
    return RatingStars.isHovered(hovered, i)
  };

  static isHovered(hovered, i) {
    if( i <= hovered ) {
      return "fas fa-star bg-green"
    }
    return "far fa-star"
  }

  isNotHovered(i) {
    const { rated } = this.props;
    if ( i <= rated ) {
      return "fas fa-star bg-green"
    }
    return "fas fa-star"
  }

  render() {
    const { onMouseOver, onRate, userHasRated, center } = this.props;
    const stars = [1,2,3,4,5];
    return (
      <Container
        center={center}
        onMouseLeave={ () => onMouseOver(-1) } >
        {stars.map((x, i) => (
          <Star
            key={i}
            i={i}
            onMouseOver={ onMouseOver }
            onRate={ onRate }
            className={this.getClassName(i)} />
          ))}
        { userHasRated &&
          <i className="fa fa-check"/>
        }
      </Container>
    )
  }
}

RatingStars.propTypes = {
  onMouseOver: PropTypes.func.isRequired,
  onRate: PropTypes.func.isRequired,
  hovered: PropTypes.number.isRequired,
  rated: PropTypes.number.isRequired,
  userHasRated: PropTypes.bool,
};

export default RatingStars

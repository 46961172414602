import React, { Component } from 'react';
import { Provider } from 'react-redux';
import GlobalRouter from './GlobalRouter';
import store from './store/store';
import './assets/css/style.css';
import ReactGA from 'react-ga';
import Firebase, { FirebaseContext } from './components/firebase';
// import * as firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";
// ReactGA.initialize('UA-98822702-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

// const firebaseConfig = {
//   apiKey: "AIzaSyDqtw7_jys9g4OvyGIc3bzpLOLBEjdnFZM",
//   authDomain: "bettergood-survey.firebaseapp.com",
//   databaseURL: "https://bettergood-survey.firebaseio.com",
//   projectId: "bettergood-survey",
//   storageBucket: "bettergood-survey.appspot.com",
//   messagingSenderId: "980347710766",
//   appId: "1:980347710766:web:9c365ee455f9a2605f5ebf",
//   measurementId: "G-KMN0NLYWVP"
// };
// firebase.initializeApp(firebaseConfig);


class App extends Component {
  constructor() {
    super();
    ReactGA.initialize('UA-98822702-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    return (
      <FirebaseContext.Provider value={new Firebase()}>
        <Provider store={store}>
          <GlobalRouter />
        </Provider>
      </FirebaseContext.Provider>
    );
  }
}

export default App;

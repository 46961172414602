import app from 'firebase/app';
import 'firebase/auth';
// import "firebase/firestore";
import "firebase/database";

const prodConfig = {
  apiKey: "AIzaSyDqtw7_jys9g4OvyGIc3bzpLOLBEjdnFZM",
  authDomain: "bettergood-survey.firebaseapp.com",
  databaseURL: "https://bettergood-survey.firebaseio.com",
  projectId: "bettergood-survey",
  storageBucket: "bettergood-survey.appspot.com",
  messagingSenderId: "980347710766",
  appId: "1:980347710766:web:9c365ee455f9a2605f5ebf",
  measurementId: "G-KMN0NLYWVP"
};

const devConfig = {
  apiKey: "AIzaSyDqtw7_jys9g4OvyGIc3bzpLOLBEjdnFZM",
  authDomain: "bettergood-survey.firebaseapp.com",
  databaseURL: "https://bettergood-survey.firebaseio.com",
  projectId: "bettergood-survey",
  storageBucket: "bettergood-survey.appspot.com",
  messagingSenderId: "980347710766",
  appId: "1:980347710766:web:9c365ee455f9a2605f5ebf",
  measurementId: "G-KMN0NLYWVP"
};

const config =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;


class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.database = app.database()
  }
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  writeUserData = (userId, name, email, imageUrl) => {
    this.database.ref('survey/jFHTspQz1wpkOa4EHP4K/').set({
      // username: name,
      // email: email,
      // profile_picture : imageUrl
      data: 'test'
    })
    .then( response => {
      console.log(response)
    })
    .catch( err => {
      console.log(err)
    })
  }
}
export default Firebase;
import imgSoup from '../../../assets/img/recipes/Shiitake-Suppe-mit-Petersilie-und-gemischten-Kraeutern-Shiitake-Soup-with-Parsley-and-Mixed-Herbs-1100x1467.jpg';
import imgOvenPotatoe from '../../../assets/img/recipes/Flower-Sprouts-mit-Ofenkartoffeln-und-Sauce-mit-getrockneten-Tomaten-Flower-Sprouts-with-baked-potatoes-and-sauce-with-dried-tomatoes-1100x1467.jpg';
import imgCutShitake from '../../../assets/img/recipes/manual/cut-shitake.jpg';

const recipe1 = {
  id: 1,
  name: 'Shiitake-Suppe mit Petersilie und gemischten Kräutern',
  image: imgSoup,
  description_short: 'Auf jeden Fall ist die Shiitake-Suppe mit Petersilie und gemischten Kräutern sehr lecker! Gesund und auch ehrlich.',
  description: "Heute Nachmittag hab ich in Windeseile eine schnelle Shiitake-Suppe auf den Tisch gezaubert. Gestern haben wir etliche frische Shiitake-Pilze gekauft. Diese leckeren Pilze kaufen wir eigentlich viel zu selten ein. Dabei passen die Pilze so wunderbar zu so vielen Gerichten, sei es auch einfach nur um Saucen zu verfeinern. Immerhin kaufen wir so gut wie immer Champignons ein \n" +
    "\n" +
    "Ich frage mich immer warum so viele Restaurants, ganz speziell asiatische, Geschmacksverstärker nehmen. Liegt es daran, dass die Köche nicht in der Lage sind etwas Schmackhaftes zu kochen? Oder liegt es an minderwertigen Zutaten? Dieses Rätsel habe ich noch nicht gelöst. Aber eines ist gewiss: So etwas esse ich ganz bestimmt nicht. Und wenn man überlegt wie schmackhaft selbst gekochtes Essen doch ist… kommen einem schon starke Zweifel.\n" +
    "\n" +
    "Auf jeden Fall ist die Shiitake-Suppe mit Petersilie und gemischten Kräutern sehr lecker! Gesund und auch ehrlich \n" +
    "\n",
  origin: {
    user_name: 'Marcel',
    user_profile: 'http://google.com',
    user_avatar: '',
  },
  time_prep: 10,
  time_cook: 10,
  time_total: 20,
  categories: [],
  rating: 4.1,
  ingredients: [
    {
      amount: 150,
      unit: 'g',
      name: 'Shiitake-Pilze',
    },
    {
      amount: .5,
      unit: 'Bund',
      name: 'Petersilie',
    },
    {
      amount: 1,
      unit: '',
      name: 'Knoblauchzehe',
    },
    {
      amount: .5,
      unit: 'Tl',
      name: 'schwarze Pfefferkörner',
    },
    {
      amount: .5,
      unit: 'El',
      name: 'Salatkräuter (getrocknet)',
    },
    {
      amount: 750,
      unit: 'ml',
      name: 'Gemüsebrühe',
    },
    {
      name: 'Kokosöl',
    },
  ],
  directions: [
    {
      img: imgCutShitake,
      text: 'Shiitake-Pilze reinigen und in Streifen schneiden, Knoblauch schälen und klein schneiden, Petersilie grob hacken',
    },
    {
      text: 'Topf erhitzen und Kokosöl schmelzen. Shiitake-Pilze und Knoblauch darin kurz anschwitzen',
    },
    {
      text: 'Gemüsebrühe, Petersilie, Pfefferkörner und Salatkräuter beigeben. Für ~10 Minuten köcheln lassen',
    },
  ],
};
const recipe2 = {
  id: 2,
  name: 'Flower Sprouts mit Ofenkartoffeln und Sauce mit getrockneten Tomaten',
  image: imgOvenPotatoe,
  description_short: 'Einfach und lecker',
  description: "Das neue Jahr hat begonnen und wir wünschen unseren Lesen noch ein gutes neues Jahr! Hoffentlich habt ihr den Jahreswechsel auch so gut wie wir überstandenBei unserem ersten Einkauf im neuen Jahr haben wir im Supermarkt Flower Sprouts entdeckt. Eine Art Rosenkohl, allerdings wurde diese Sorte während 15 Jahren speziell gezüchtet und dabei stets verfeinert. Das Resultat kann sich wirklich sehen lassen! Auch wir sind auf den Geschmack gekommen und haben große Freude an Flower Sprouts. Geschmacklich besser wie Rosenkohl (ist auch nicht so Bitter ) und lässt sich wunderbar und ganz schnell zubereiten. In dem Gemüse steckt sogar die doppelte Menge an Vitamin C.",
  origin: {
    user_name: 'Marcel',
    user_profile: 'http://google.com',
    user_avatar: '',
  },
  time_prep: 20,
  time_cook: 10,
  time_bake: 25,
  time_total: 45,
  categories: [],
  rating: 4.6,
  ingredients: [
    {
      amount: 1.5,
      unit: 'kg',
      name: 'Shiitake-Pilze',
    },
    {
      amount: 1,
      unit: 'EL',
      name: 'Olivenöl',
    },
    {
      amount: 1,
      unit: 'TL',
      name: 'Salz',
    },
    {
      amount: 40,
      unit: 'g',
      name: 'Tomaten (getrocknet)',
    },
    {
      amount: 1,
      name: 'Zwiebel',
    },
    {
      amount: 3,
      name: 'Knoblauchzehen',
    },
    {
      amount: 2,
      unit: 'EL',
      name: 'Tomatenmark',
    },
    {
      amount: 1,
      unit: 'Tl',
      name: 'Senf',
    },
    {
      amount: 3,
      unit: 'EL',
      name: 'Salatkräuter (getrocknet)',
    },
    {
      amount: 1,
      unit: 'Tl',
      name: 'Schwarze Pfefferkörner',
    },
    {
      amount: 2,
      unit: 'El',
      name: 'Süßlupinenmehl',
    },
    {
      amount: 50,
      unit: 'ml',
      name: 'Wasser',
    },
    {
      name: 'Salz',
    },
    {
      amount: 250,
      unit: 'g',
      name: 'Flower Sprouts',
    },
  ],
  directions: [
    {
      text: 'Kartoffeln schälen und in ~2 cm große Stücke schneiden. Kartoffeln mit dem Olivenöl und dem Salz gut vermengen (z.B. in einer Schüssel schütteln - mit Deckel/Teller)',
    },
    {
      text: 'Kartoffeln auf einem Backblech mit Backpapier platzieren und im vorgeheizten Ofen bei 180 °C für ~25 Minuten goldbraun backen',
    },
    {
      text: 'Zwiebel und Knoblauch schälen, beides in kleine Würfel schneiden. Getrocknete Tomaten ebenfalls in Würfel schneiden',
    },
    {
      text: 'Kokosöl in einer Pfanne erhitzen und die Zwiebelwürfel für 2 Minuten andünsten. Knoblauch und getrocknete Tomaten beigeben und für weitere 2 Minuten mit andünsten',
    },
  {
      text: 'Tomatenmark, Senf, Salatkräuter und Pfefferkörner hinzugeben, kurz verrühren und mit kochendem Wasser ablöschen',
    },
  {
      text: 'Süßlupinenmehl gut unterrühren und alles zusammen kurz aufkochen',
    },
  {
      text: 'Je nach Vorliebe etwas Wasser beigeben oder die Sauce leicht köcheln lassen bis die gewünschte Konsistenz erreicht ist und Sauce ggf. mit etwas Salz abschmecken',
    },
  {
      text: 'Flower Sprouts waschen und im kochenden Wasser für 4 Minuten köcheln, anschließend herausnehmen',
    },

  ],
};

const recipes = [
  recipe1,
  recipe2,
  recipe1,
  recipe2,
  recipe1,

];

export default recipes;
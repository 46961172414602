import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RatingStars from "../../components/rating-stars/RatingContainer";

const Container = styled.div`
  display: flex;
  position: relative;
  font-size: 10pt;
  background-color: white;
  height: 40px;
  align-items: center;
  z-index: 10;
  span {
    flex: 1;
    text-align: right;
    padding-right: 4px;
    color: ${ props => ratingToColor(props.rating) };
  }
`;

function ratingToColor(rating) {
  switch (true) {
    case (null):
      return 'unbewertet';
    case (rating > 1):
      return 'var(--bg-plus3)';
    case (rating > .5):
      return 'var(--bg-plus2)';
    case (rating > 0):
      return 'var(--bg-plus1)';
    case (rating < 0 && rating >= -.5):
      return 'var(--bg-minus1)';
    case (rating < -.5 && rating >= -1):
      return 'var(--bg-minus2)';
    case (rating < -1):
      return 'var(--bg-minus3)';
    default:
      return 'unbewertet'
  }
}

function ratingToText(rating) {
  switch (true) {
    case (null):
      return 'unbewertet';
    case (rating > 1):
      return 'besonders gesund';
    case (rating > .5):
      return 'gesund';
    case (rating > 0):
      return 'eher gesund';
    case (rating < 0 && rating >= -.5):
      return 'eher ungesund';
    case (rating < -.5 && rating >= -1):
      return 'ungesund';
    case (rating < -1):
      return 'besonders ungesund';
    default:
      return 'unbewertet'
  }
}

const RatingBar = ({
  stars,
  rating
}) => (
  <Container rating={rating}>
    <RatingStars rating={stars} />
    <span>{ratingToText(rating)}</span>
  </Container>
);

RatingBar.propTypes = {};

export default RatingBar

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import RatingStars from './RatingStars';

class RatingContainer extends PureComponent {

  constructor(props) {
    super()
    this.state = {
      hovered: -1,
      rated: Math.round(props.rating) -1,
      userHasRated: false,
    }
  }

  onMouseOver = i => {
    this.setState({hovered: i})
  };

  onRate = i => {
    this.setState({
      rated: i,
      userHasRated: true,
    })
  }


  render() {
    const { hovered, rated, userHasRated } = this.state;
    return (
      <RatingStars
        center={this.props.center}
        onMouseOver={this.onMouseOver}
        onRate={ this.onRate }
        hovered={ hovered }
        rated={ rated }
        userHasRated={ userHasRated }
      />
    )
  }
}

RatingContainer.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default RatingContainer

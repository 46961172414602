import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.ul`
  display: flex;
  // background-color: red;
  padding: 0;
  justify-content: space-between;
  margin: 0;
`;

const Item = styled.li`
  font-size: ${ props => props.size ? props.size + 'px' : '13px' };
  list-style: none;
  color: #909090;
  i {
    color: var(--bg-green);
    margin-right: 10px;
    font-size: ${ props => props.size ? props.size + 2 + 'px' : '13px' };
  }
`;

const Icon = styled.i`
  color: var(--bg-green);
  margin-right: 10px;
  font-size: ${ props => props.size ? props.size + 'px' : '13px' };
`;

class OverviewBar extends PureComponent {

  state = {
    isFavorite: false,
  };

  toggleLike = () => {
    if ( this.state.isFavorite ) {
      this.unlike()
    } else {
      this.like()
    }
  };

  like() {
    this.setState({ isFavorite: true })
  }

  unlike() {
    this.setState({ isFavorite: false })
  }

  render() {
    const className = this.state.isFavorite ? "fas fa-heart" : "far fa-heart";
    const { duration, user, size } = this.props;
    return (
      <Container>
        <Item size={ size }>
          <i className="fas fa-clock" />
          <span>{duration} Minuten</span>
        </Item>
        <Item size={ size }>
          <i className="fas fa-user" />
          <span>von {user}</span>
        </Item>
        <Item size={ size } onClick={ this.toggleLike }>
          <i className={className} />
          <span>4</span>
        </Item>
      </Container>
    );
  }
}

OverviewBar.propTypes = {
  duration: PropTypes.number.isRequired,
  user: PropTypes.string.isRequired,
  size: PropTypes.number,
};

export default OverviewBar

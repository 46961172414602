import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import OverviewBar from './OverviewBar';
import RatingBar from './RatingBar';
import RatingStars from '../../components/rating-stars/RatingContainer';
import imgPlaceholder from '../../assets/img/recipes/breakfast.jpg';

const Container = styled.div`
overflow: hidden;
  width: 100%; 
  text-align: center;
  margin-bottom: 20px;
`;

const Image = styled.div`
  height: 270px;
  background-image: url(${props => props.image ? props.image: imgPlaceholder });
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1);
  transition: transform .8s ease;
  ${Container}:hover & {
    transform: scale(1.05)
  }
`;

const Content = styled.div`
  min-height: 240px;
  padding: 5px 30px 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const Headline = styled.p`
  font-size: 16pt;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 10px;
  flex: 1 1 80px;
`;


class SingleRecipe extends PureComponent {
  render() {
    const {
      name,
      image,
      description_short,
      origin,
      time_total,
      rating
    } = this.props;
    return (
      <div className="col-lg-4 col-md-6 col-12">
        <Container>

          <Link to={{
            pathname: `recipes/${name}`,
            state: { ...this.props }
          }}>
            <Image image={image} />
          </Link>
          <RatingBar
            stars={ rating }
            rating={.1} />
          <Content>
            <Link to={{
              pathname: `recipes/${name}`,
              state: { ...this.props }
            }}>
            <Headline>
              {name}
            </Headline>
              
            </Link>
            <Description>
              {description_short}
            </Description>
            <OverviewBar duration={time_total} user={origin.user_name} />
          </Content>
        </Container>
      </div>
    )
  }
}

SingleRecipe.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description_short: PropTypes.string.isRequired,
  origin: PropTypes.object.isRequired,
  time_total: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
};

export default SingleRecipe

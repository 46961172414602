import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Card from './Card';

const Container = styled.div`
  // display: flex;
  max-width: 960px;
  margin: 0 auto;
`;

const ImageHeader = styled.div`
  height: 200px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,.5) 0%,rgba(0,0,0,.5) 100%),
  url(${ props => props.image });
  background-position: center, center;
  background-size: cover;
`;

// const Card = styled.div`
//   height: 400px;
//   width: 700px;
//   background-color: white;
//   margin-top: -80px;
//   img {
//     width: 100%;
//     height: 220px;
//     object-fit: cover;
//   }
// `;

class RecipeContainer extends PureComponent {

  componentDidMount() {
  }

  render() {
    const { image } = this.props.location.state;
    return (
      <div>
        <ImageHeader image={ image } />
        <Container>
        <div className="container">
          <div className="row">
            <Card {...this.props.location.state} />
          </div>
        </div>
      </Container>
      </div>
    )
  }
}

RecipeContainer.propTypes = {};

export default RecipeContainer

import axios from "axios";

function convertData(data) {
  const string = JSON.stringify( data )
  const toSend = 'form=' + string
  return toSend
}

export default async function submitData(
  url = 'https://baggid.com/php/survey.php',
  data
) {
  const convertedData = convertData(data);
  const promise = axios({
    method: 'post',
    url,
    data: convertedData
  })
  .then(function (response) {
    return response
  })
  .catch(function (error) {
    return error
  });
  return await promise;
}
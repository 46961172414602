import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RatingStars from '../../../components/rating-stars/RatingContainer';
import OverviewBar from '../OverviewBar';

const Container = styled.div`
  height: 400px;
  width: 700px;
  background-color: white;
  margin-top: -80px;
`;

const Header = styled.div`
  width: 100%;
  // min-height: 160px;
  background-color: #F8F8F8;
  padding: 30px 35px 20px;
  h1 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #717171; 
  }
`;

const Image = styled.img`
  width: 100%;
  height: 380px;
  object-fit: cover;
`;

const Bar = styled.div`
  width: 100%;
  padding: 17px 20px;
  background-color: #F8F8F8;
`;


class Card extends PureComponent {
  render() {
    const { name, image, rating, time_prep, origin } = this.props;
    return (
      <Container>
        <Header>
          <h1>
            { name }
          </h1>
          <RatingStars rating={rating}/>
        </Header>
        <Image src={ this.props.image } />
        <Bar>
          <OverviewBar size={15} duration={time_prep} user={origin.user_name} />
        </Bar>
      </Container>
    )
  }
}

Card.propTypes = {};

export default Card
